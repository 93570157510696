<template>
  <div class="d-flex flex-column">
    <v-row v-for="(item, index) in psychologistInfo" :key="index">
      <v-col cols="12" md="12">
        <span>{{ item.date.translated }}</span>
        <div class="d-flex flex-wrap timePicker">
          <v-btn
            v-for="(slot, i) in item.slots"
            :key="slot.timeId"
            @click="dataToEmit({ slot, date: item.date }, i, $event)"
            class="ml-2 mt-2 btn-disable"
            outlined
            :disabled="slot.is_booked || !slot.available"
          >
            {{ slot.time }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "showDateTimePicker",
  data() {
    return {
      multiSelected: [],
      prevBtn: null,
      patientName: "Господин",
      psychologistInfo: [],
      resheduleSessionAvailable: true,
      activeSlot: null,
      selectedSlot: null,
      selectedSlotBtn: null,
      nextSessionInfoAproved: false,
      nextSessionInfo: {
        sessionDay: "Вторник",
        sessionTime: "18:00",
        sessionDate: "21.01.22",
      },
    };
  },
  props: {
    isMultiSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    handleUserSelectData: {
      type: Function,
      required: false,
      default: () => function () {},
    },
    defaultValue: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    resheduleSession() {
      this.nextSessionInfoAproved = false;
    },
    cancelResheduleSession() {
      this.nextSessionInfoAproved = true;
    },
    dataToEmit(selectedTimeSlot, i, event) {
      this.selectedSlot = selectedTimeSlot;
      this.activeSlot = i;

      if (this.isMultiSelect) {
        if (
          this.multiSelected.find(
            (el) => el.slot.id === selectedTimeSlot.slot.id
          )
        ) {
          this.multiSelected = this.multiSelected.filter(
            (el) => el.slot.id !== selectedTimeSlot.slot.id
          );
        } else {
          this.multiSelected.push(selectedTimeSlot);
        }
      } else {
        if (this.prevBtn) {
          this.prevBtn.classList.remove("active");
        }
        this.$emit("userSelect", this.selectedSlot);
      }

      this.saveTimeSlotToProps(selectedTimeSlot);

      if (event.target.tagName === "SPAN") {
        this.prevBtn = event.target.parentElement;
        event.target.parentElement.classList.toggle("active");
      } else {
        this.prevBtn = event.target;
        event.target.classList.toggle("active");
      }
    },
    saveTimeSlotToProps(selectedTimeSlot) {
      let psychologist = {
        name: this.defaultValue.name,
        id: this.defaultValue.id,
        timeSlot: {
          ...selectedTimeSlot,
        },
      };

      this.handleUserSelectData(psychologist);
    },
  },
  beforeMount() {
    this.psychologistInfo = this.defaultValue.time_slots;
    // this.defaultValue.time_slots

    // this.psychologistInfo.availableTimeSlots.map((el) => {
    //   el.slots.map((item) => {
    //     item.isDisable
    //       ? this.multiSelected.push({ slot: item, date: el.date })
    //       : [];
    //   });
    // });
  },
};
</script>

<style scoped>
.timePicker {
  width: 100%;
}

.btn-disable {
  width: 84px;
  color: #59b6b9;
}

.active {
  color: #ffffff !important;
  background-color: #59b6b9 !important;
}
</style>
